@keyframes slide-down {
	from { transform: translateY( -100% ); }
	to { transform: translateY( 0% ); }
}

@keyframes slide-up {
	from { transform: translateY( 0% ); }
	to { transform: translateY( -100% ); display: none; }
}

@keyframes fade-in {
	from { opacity: 0; visibility: hidden; }
	to { opacity: 0.6; visibility: visible; }
}

@keyframes fade-out {
	from { opacity: 0.6; visibility: visible; }
	to { opacity: 0; visibility: hidden; }
}

@keyframes cookie-directive-close-options {
	0% { max-height: 282px; }
	100% { max-height: 0px; }
}

/** @Chris
	e.g.
		<div id="ez_waiting_animation">
			<div class="wave">Your text</div>
		</div>
	The text itself is transparent, because of the color: transparent.
	we use this to 'fill up' the element with the background-image which can be set to a color or well...an image or whatever you need.
*/

// .wave {
// 	background-image: linear-gradient( 0deg, ezentrum_variables.$primary-color, ezentrum_variables.$alert-color );
// 	animation: fill-up 5s infinite linear;
// 	font-weight: bold;
// 	background-size: 100%;
// 	background-repeat: repeat-x;
// 	opacity: 1;
// 	background-clip: text;
// 	-webkit-background-clip: text;
// 	color: transparent;
// 	height: 5vw;
// 	font-size: 5vw;
// 	display: flex;
// 	align-items: center;
// 	margin: auto;
// }
// @keyframes fill-up {
// 	0%{ background-size:100% 0px; background-position:0 bottom; };
// 	100%{ background-size:100% 100%; background-position:100% bottom; };
// }



/**
	e.g.
		<div id="ez_waiting_animation">
			<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
				<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
			</svg>
		</div>
		This can be used for an animation circle, which rotates.
		Offset: higher value => less 'elemental dots' (larger continuose line)
	e.g replace $offset:5; with $offset: 168;
	and delete : stroke-dasharray: $offset * 25; inside the dash keyframe
	$offset: 5;
	$duration: 2.5s;

	.spinner {
		animation: rotation $duration linear infinite;
		display: flex;
		align-items: center;
		margin: auto;
		animation-play-state: paused;
	}

	@keyframes rotation {
		0% { transform: rotate(0deg); }
		100% { transform: rotate(270deg); }
	}

	.path {
		stroke-dasharray: $offset;
		stroke-dashoffset: 0;
		transform-origin: center;
		animation: dash $duration ease-in-out infinite, colors ($duration * 4) ease-in-out infinite;
		animation-play-state: paused;
	}

	@keyframes colors {
		0% { stroke: ezentrum_variables.$primary-color; }
		25% { stroke: ezentrum_variables.$body-font-color; }
		50% { stroke: ezentrum_variables.$primary-color; }
		100% { stroke: ezentrum_variables.$body-font-color; }
	}

	@keyframes dash {
		0% { stroke-dashoffset: $offset; }
		50% {
			stroke-dashoffset: $offset/4;
			transform:rotate(135deg);
			stroke-dasharray: $offset * 25;
		}
		100% {
			stroke-dashoffset: $offset;
			transform:rotate(450deg);
		}
	}


*/