@mixin icon (){

	.icon {
		padding: 5px;

		background-color: black;
		color: white;

		transition: transform 0.2s ease-in-out;
	}

	.icon:hover{
		cursor: pointer;
		transform: scale( 1.1 );
	}
}

@mixin flex (){
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
}

@mixin flex-vertical-align {
	-ms-flex-align: center;
	-webkit-align-items: center;
	-webkit-box-align: center;
	align-items: center;
}

@mixin play-animation ( $name, $duration ){
	-webkit-animation-name: $name;
	-webkit-animation-duration: $duration;
	-moz-animation-name: $name;
	-moz-animation-duration: $duration;
	-ms-animation-name: $name;
	-ms-animation-duration: $duration;
	animation-name: $name;
	animation-duration: $duration;
}

@mixin overlay-position ( $width ){
	width: $width;
	left: calc( 50% - ( #{$width} / 2 ) );
}
// @Chris Can be used, e.g. on a button, creates a left to right fade-in effect
@mixin slide-in-out( $text-color, $border-color, $bgr-color ) {
	color: $text-color;
	border: 2px solid $border-color;
	background-color: transparent;
	position: relative;
	z-index: 1;
	transition: 0.3s ease;
	border-radius: 5px;
	font-weight: normal;
	overflow: hidden;
	&::before, &::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		background-color: $bgr-color;
	}

	&::before {
		left: 100%;
		transition: 0.3s left ease-in-out;
		z-index: -1;
		opacity: 1;
	}
	&::after {
		right: 100%;
		transition: 0.3s right ease-in-out;
		z-index: -2;
		opacity: 0;
	}
	&:hover {
		color: $text-color;
		&:before {
			left: 0;
			z-index: -2;
			opacity: 0;
		}
		&:after {
			right: 0;
			z-index: -1;
			opacity: 1;
		}
	}
}